.border-top-add{
    border-top-width:5px;
}

.slider-testimonials  {
    .card {
        margin-right: 20px;
    }
    .card-item{
        padding:3rem 2rem;
    }
    .slick-dots{
        bottom:-45px;
    }
  }


  .lh-loose {
    h3{
        margin-bottom: 1rem;
    }
  }

  .p-spacing{
    p{
        margin-bottom: 1rem;
        &:last-child{
            margin-bottom: 0;
        }
    }
  }