@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');


body{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

h2{
    margin-bottom: 1rem;
}