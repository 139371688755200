@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

@import "modules/variables";

@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";
@import "@webpixels/css/utilities";
@import "bootstrap/scss/utilities/api";


// Modules
@import "modules/media_query_mixins";
@import "modules/typography";
@import "modules/buttons";
@import "modules/form";

@import "modules/header";

// Components
@import "components/hero-carousel";
@import "components/introduction";
@import "components/cta-subfooter";
@import "components/page-header";
@import "components/text";

@import "modules/global";

footer{
    background-color:#545454;
    color:white;
    font-size: 90%;
    a{
        display: inline-block !important;
        color:white;
        &:after{
            content: '|';
            display: inline-block;
            padding-left: 0.3rem;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
    }
}

.component-services{
    ul{
        padding-left: 1rem;
    }
}

.text-wrapper{
    h1, h2, h3, h4, p {
        margin-bottom: 0.5rem;
    }
}

.fixed-min-height{
    min-height:300px;
}

:root {
    --x-accordion-btn-icon-white: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3E%3Cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
}

$accordion-border-radius: 5px;
$accordion-shadow: 0px 0px 2px #939393;
$accordion-margin-top: 2rem;

.faq-block {
    box-shadow: $accordion-shadow;
    padding: 0;
    border-radius: $accordion-border-radius;
    margin-top: $accordion-margin-top;

    .accordion-item {
        // Nested item styles here

        &:hover, &:focus {
            // Hover and focus styles here
        }

        .accordion-button {
            // Button styles here
            outline:none;

            &:hover, &:focus {
                // Button hover and focus styles here
                box-shadow: none;
                outline: none;
            }
            background-color: $primary;
            color:white;

            &.collapsed{
                background-color: white;
                color:var(--x-body-color);
            }
            &:not(.collapsed):after {
                // Change the color of the icon to white
                background-image: var(--x-accordion-btn-icon-white);
            }
        }

        .accordion-button:not(.collapsed){
            box-shadow:none;
        }

        .accordion-collapse {
            transition: all 0.3s ease; // Smooth transition for collapsing

            .accordion-body {
                // Body styles here
            }
        }
    }
}


