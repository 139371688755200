.top-bar{
    .navbar-nav{
        flex-direction:inherit;
        justify-content: flex-end;
    }
    .nav-item{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background:none;
        color:white;
    }
}

.navbar {
    --x-navbar-bg: #fff;
    --x-navbar-color: $primary;
    --x-navbar-icon-color: $primary;
    --x-navbar-hover-bg: #f5f9fc;
    --x-navbar-hover-color: darken($primary, 10);
    --x-navbar-active-bg: #f5f9fc;
    --x-navbar-active-color: $primary;
    --x-navbar-nav-link-padding-y: 1rem;
    z-index: 100;
    &.main-nav{
        // border-bottom:2px solid $primary;
    }
}