.hero-carousel {
    .carousel-inner {
        .carousel-item {
            position: relative; /* Add this line */
            background: {
                repeat: no-repeat;
                size: cover;
            }
            width: 100%;
            height: 400px;

            display: flex;
            align-items: center;
            justify-content: center;
            
            @include for-desktop-up {
                height: 500px;
            }
            
            @include for-bigger-desktop-up {
                height: 600px;
            }
            
            &.with-overlay{
                /* Add the following styles for the semi-transparent overlay */
                &::before {
                    z-index: 0;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity as needed */
                }
            }

            .container {
                z-index: 1;
                .carousel-heading {
                    color: white;
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                }
                
                .carousel-text {
                    p {
                        color: white;
                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                    }
                }
                @include for-tablet-landscape-up{
                    .carousel-heading {
                        font-size: 2.5rem;
                    }
                }
                @include for-bigger-desktop-up{
                    .carousel-heading {
                        font-size: 2.8rem;
                    }
                }
            }
        }
    }
}
