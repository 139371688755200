.page-header{
    position: relative;
    background:{
        repeat:no-repeat;
        size:cover;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 0.5);
      z-index: 0;
    }
    .header-text{
        position: relative;
        z-index: 1;
        display: inline-block;
        color:white;
        margin:4rem 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        @include for-tablet-portrait-up {
            margin:5rem 0;
        }
        @include for-big-desktop-up {
            margin:7rem 0;
        }
    }
}