.cta-subfooter {
    i{
        font-size: 2.5rem;
        color:$primary;
    }
    .text-content{
        line-height: 1.2;
        .h4{
            color:$primary;
            font-weight:500;
        }
    }
}
.empty{
    display: none;
}